import React from 'react';

import type { TRuleWithTheme } from '@ackee/fela';

import { CaseHeader, Gallery, GalleryLayout, HeroBanner, Team, TextContent } from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/jtbanka.png';

const CASE_ID = 'jtbanka';
const MODULE_NAME = 'jtbanka';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    gradient1: 'linear-gradient(0deg, #010E0E, #010E0E)',
    gradient2: 'linear-gradient(180deg, #06302E 0%, rgba(4, 48, 46, 0.167628) 15.03%, rgba(3, 48, 46, 0) 34.99%)',
    bgGreen: '#03302E',
    bgLight: '#F9F9F9',
    white: '#FFF',
    black: '#000',
};

const visibleFromMd: TRuleWithTheme = () => ({
    display: 'none',
    tablet: {
        display: 'block',
    },
});
const hiddenFromMd: TRuleWithTheme = () => ({
    tablet: {
        display: 'none',
    },
});

const team = [
    {
        department: 'references.case.team.management',
        names: ['Marek Elznic'],
    },
    {
        department: 'references.case.team.android',
        names: ['Petr Polívka', 'Jan Steuer', 'Michal Pešek'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Igor Rosocha', 'Alex Grigorov', 'Vladislav Komkov', 'Natália Pohanková'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Anna Zmeevskaja', 'Martin Votýpka'],
    },
];

interface Props {}

const JTBankaCaseStudy = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            extend={{ subTitleContainer: () => ({ paddingBottom: '5rem' }) }}
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/main.png`,
                    style: {
                        height: '100%',
                    },
                    imgStyle: {
                        top: '0px',
                        objectPosition: 'center',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            background={colors.bgGreen}
            backgroundImage={{
                src: `${IMAGE_PATH}/main-bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                    bottom: '0px',
                    height: 'calc(90% - 46px)',
                    paddingTop: '10%',
                    left: 'unset',
                },
            }}
            headerTheme="light"
            textColor={colors.white}
        />

        <TextContent
            headerTheme="light"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.FINANCE,
                outputs: [
                    'references.case.output.ios',
                    'references.case.output.android',
                    'references.case.output.testing',
                ],
            }}
            background={colors.bgGreen}
            extend={{ categoryLink: () => ({ color: colors.white }) }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            extend={{ containerWrapper: hiddenFromMd }}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/1-1.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/1-2.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/1-bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'center',
                },
                style: {
                    height: 'auto',
                    bottom: 0,
                },
            }}
            background={colors.bgGreen}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            extend={{ containerWrapper: visibleFromMd }}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/1.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/1-bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'center',
                },
                style: {
                    height: 'auto',
                    bottom: 0,
                },
            }}
            background={colors.bgGreen}
        />

        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
            background={colors.bgLight}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/2.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/2-bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'center',
                },
                style: {
                    height: 'auto',
                },
            }}
            background={colors.bgLight}
        />

        <TextContent
            headerTheme="dark"
            // textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.bgLight}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/3.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                            width: '95%',
                            padding: '0 2.5%',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/3-bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'center',
                },
                style: {
                    height: 'auto',
                },
            }}
            background={colors.bgGreen}
        />

        <TextContent
            headerTheme="light"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            background={colors.bgGreen}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4-1.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            height: '80%',
                            top: '10%',
                        },
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/4-2.png`,
                        imgStyle: {
                            height: '80%',
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            top: '10%',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/4-bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                    top: '0px',
                },
                style: {
                    height: '100%',
                    top: 0,
                },
            }}
            background={`${colors.gradient1}, ${colors.gradient2}`}
        />

        {/* TODO: přidat fotku + texty */}
        {/* <Testimonial
            headerTheme="dark"
            background={colors.bgLight}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_jtbanka.jpeg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        /> */}

        {/* @ts-expect-error */}
        <Team headerTheme="dark" content={team} background={colors.white} textColor={colors.black} />
    </>
);

export default JTBankaCaseStudy;
