import type { TechnologyCategory } from 'modules/ui';

import { Technologies } from '../../../../images';

const columns = [
    'html5',
    'react',
    'typescript',
    'googlecloud',
    'gitlab',
    'nextjs',
    'tanstackquery',
    'playwright',
    'vitest',
    'mui',
    'stackshare',
] as const;

const items: TechnologyCategory['items'] = columns.map(id => ({
    id,
    title: `webAppDevelopmentPage.technologies.${id}.title`,
    image: Technologies[`${id}`],
}));

items[columns.length - 1].link = 'common.links.stackShare';

export const content: TechnologyCategory = {
    items: [...items],
    sectionTitle: 'webAppDevelopmentPage.technologies.sectionTitle',
    name: 'careerPage.technologies.web.name',
};
